import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LanguageContext } from '../../../context/LanguageContext'
import SearchCard from '../../SearchCard'
import RenderIf from '../../RenderIf'
import {
  Node__Career_Pathway,
  Query,
  Paragraph__Career_Pathways_Listing,
} from '../../../types/generated'

export const ParagraphCareerPathwaysListingFields = graphql`
  fragment ParagraphCareerPathwaysListingFields on paragraph__career_pathways_listing {
    __typename
    id
    field_title
  }
`

const ParagraphCareerPathwayListing = (props: Paragraph__Career_Pathways_Listing) => {
  const { field_title: fieldTitle } = props
  const { langcode } = useContext(LanguageContext)
  const data = useStaticQuery<Query>(graphql`
    query CareerPathwayListingParagraphQuery {
      allNodeCareerPathway(sort: { fields: title, order: ASC }) {
        nodes {
          ...NodeCareerPathwayFields
        }
      }
    }
  `)

  const allNodeCareers = data?.allNodeCareerPathway?.nodes
  // @todo: remove any type
  const currentNodes = allNodeCareers?.filter(
    (career: Node__Career_Pathway) => career.langcode === langcode,
  )

  return (
    <section className="max-w-7xl" aria-labelledby="contact-heading">
      <RenderIf condition={!!fieldTitle}>
        <h1 className="font-bold text-center text-3xl mb-24">{fieldTitle}</h1>
      </RenderIf>
      <div className="grid grid-cols-1 gap-y-16 my-8 lg:grid-cols-3 lg:gap-x-8">
        {currentNodes.map((careerPathway: any) => (
          <SearchCard key={careerPathway.id} showBackground card={careerPathway} />
        ))}
      </div>
    </section>
  )
}

export default ParagraphCareerPathwayListing
